<template>
    <section class="feedback">
        <div class="feedback-wrapper">
            <CommonBlock
                title="Контроль"
                :visible-back-btn="true"
            >
                <div class="img">
                    <img src="../assets/images/cabinet-img.svg" alt="cabinet">
                </div>
            </CommonBlock>

            <div class="feedback-content">
                <form class="feedback-form" @submit.prevent="send">
                    <input type="hidden" name="type" v-model="form.type">
                    <div class="feedback-form_group r-flex">
                        <div class="feedback-form_field">
                            <label>Назва</label>
                            <input type="text" name="title">
                            <span class="error-label" v-if="errors.title">{{errors.title}}</span>
                        </div>
                        <div class="feedback-form_field">
                            <CustomSelectV2
                                id="channel_id"
                                label="Канал"
                                v-model="form.channel_id"
                                :error="errors.channel_id"
                                :options="[{label: 'Чоловіча', value: 0}, {label: 'Жіноча', value: 1}]"
                            />
                            <span class="error-label" v-if="errors.channel_id">{{errors.channel_id}}</span>
                        </div>
                        <div class="registartion-form_field d-flex">
                            <div class="col">
                                <label for="activity_date">Дата</label>
                                <v-date-picker v-model="form.activity_date" :model-config="{type: 'string', mask: 'YYYY-MM-DD'}" :update-on-input="false" locale="uk" :dark="true">
                                    <template v-slot="{ inputEvents }">
                                        <input
                                            class="registartion-form_date"
                                            :value="form.activity_date"
                                            v-on="inputEvents"
                                            id="activity_date"
                                            name="activity_date"
                                        />
                                    </template>
                                </v-date-picker>
                                <span class="error-label" v-if="form.activity_date">{{errors.activity_date}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="feedback-form_group">
                        <label>Опис</label>
                        <textarea name="text"></textarea>
                        <span class="error-label" v-if="form.description">{{errors.description}}</span>
                    </div>
                    <CustomButton label="Зберегти"/>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import CustomButton from "../components/CustomButton";
import CommonBlock from "../components/CommonBlock";
import CustomSelectV2 from '../components/ui/CustomSelectV2'
import ActivityService from "../services/ActivityService";

export default {
    name: 'control-form',
    props: {
        update: {
            type: Boolean
        }
    },
    data() {
        return {
            errors: {},
            form: {},
        }
    },
    components: {
        CustomButton,
        CommonBlock,
        CustomSelectV2
    },
    created() {
        this.type = this.$route.query.type === null ? 1 : this.$route.query.type;
    },
    catchHandler(resp) {
        if(resp.statusCode === 422) {
            this.errors = resp.data;
        }
    },
    methods: {
        async send(e) {
            let res = await ActivityService.send(new FormData(e.target)).catch(this.catchHandler);

            if(res.status === 'success') {
                this.$notify({
                    text: 'Успішно надіслано!',
                    type: 'success',
                });
                await this.$router.push('/control?type' + this.type);
            }
        },
    }
}
</script>


<style scoped lang="less">
    .r-flex {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .feedback {
        &-wrapper {
            position: relative;
            display: flex;
        }

        &-inner {
            width: 30%;
            height: 100vh;
            background: url(../assets/images/calendar-bg.jpg) no-repeat center;
            background-size: cover;
            padding: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;
        }

        .error-label{
            position: absolute;
            top: 100%;
            right: 0;
            color: #ffc900;
            font-size: 13px;
            text-align: right;
        }

        &-title {
            font-weight: bold;
            font-size: 56px;
            line-height: 64px;
            letter-spacing: -0.392px;
            color: #FFFFFF;
            margin-bottom: 149px;
        }

        &-img {
            margin-bottom: 60px;
        }

        &-content {
            padding: 40px 120px 40px 120px;
            width: 70%;
            height: 100vh;
            display: flex;
            align-items: center;
        }

        &-form {
            max-width: 1280px;
            width: 100%;
            margin: 0 auto;

            &_field {
                max-width: 480px;
                width: 48%;
                position: relative;
            }

            &_group {
                margin-bottom: 20px;
                position: relative;
            }

            label {
                display: block;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                color: #1A2744;
                padding-left: 20px;
                margin-bottom: 6px;
            }

            input {
                height: 56px;
                max-width: 480px;
                width: 100%;
                border: 1px solid #FFC900;
                border-radius: 15px;
                outline: none;
                padding-left: 20px;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                color: #1A2744;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                &::placeholder {
                    color: #1A2744;
                }
            }

            select {
                height: 56px;
                max-width: 480px;
                width: 100%;
                border: 1px solid #FFC900;
                border-radius: 15px;
                outline: none;
                padding-left: 20px;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                color: #1A2744;
                background-color: #fff;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background: url(../assets/images/select.svg) no-repeat center right 20px;
            }

            textarea {
                // max-width: 1040px;
                width: 100%;
                height: 280px;
                border: 1px solid #FFC900;
                border-radius: 15px;
                resize: none;
                outline: none;
                padding: 20px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }

            button {
                margin: 50px auto 0;
                display: block;
            }
        }
    }

    .header {
        width: 100%;
    }

    .header-logo {
        //   width: 253px;
        height: 51px;
        margin-bottom: 22px;
        display: inline-block;

        img {
            width: 100%;
            height: 100%;
        }
    }


    /* breadcrumb */
    .breadcrumb {
        list-style: none;
        margin-bottom: 45px;
    }

    .breadcrumb-item {
        display: inline;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #fff;
        margin-right: 20px;
    }

    .breadcrumb-link {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #ffc900;
        text-decoration: none;
        position: relative;

        &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 15px;
            background: #fff;
            bottom: 1px;
            right: -11px;
        }
    }

    .btn-back {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #ffc900;
        text-decoration: none;

        &_icon {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background: #ffc900;
            box-shadow: 0px 24px 24px -14px rgba(249, 198, 35, 0.35);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
        }
    }
    .feedback-img-m {
        display: none;
    }

    @media screen and (max-width: 1920px) {
        .feedback {
            &-inner {
                max-width: 640px;
                width: 30%;
            }

            &-content {
                max-width: 1280px;
                width: 70%;
            }

            &-img {
                margin-bottom: 60px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .feedback-content {
            padding-top: 150px;
        }

        .feedback-title {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 50px;
        }

        .feedback-inner {
            padding: 40px 40px 40px 60px;
        }

        .feedback-img {
            width: 250px;
            height: 200px;
        }

        .feedback-content {
            padding: 50px 60px;
        }

        .breadcrumb {
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width: 1200px) {
        .feedback-title {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 30px;
        }

        .feedback-img {
            width: 150px;
            height: 150px;
            margin-bottom: 40px;
        }

        .feedback-inner[data-v-65d59982] {
            padding: 40px 30px 30px 50px;
        }
    }

    @media screen and (max-height: 950px) {
        .feedback-title {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 70px;
        }

        .feedback-img {
            width: 300px;
        }
    }

    @media screen and (max-width: 950px) {
        .feedback-wrapper {
            flex-wrap: wrap;
        }
        .feedback-img {
            display: none;
        }
        .feedback-content {
            width: 100%;
            height: auto;
            padding: 20px;
            flex-wrap: wrap;
        }
        input, textarea, select {
            font-size: 16px;
        }
    }


    @media screen and (max-height: 850px) {
        .feedback-content {
            padding: 150px 60px 50px 60px;
        }

        .feedback-img {
            width: 220px;
        }
    }

    @media screen and (max-height: 770px) {
        .feedback-content {
            padding: 50px 60px 0 60px;
        }

    }

    @media screen and (max-height: 670px) {
        .feedback-title {
            font-size: 24px;
            line-height: 35px;
            margin-bottom: 50px;
        }

        .feedback-img {
            width: 145px;
            height: 142px;
            margin-bottom: 40px;
        }
    }
    @media screen and (max-width: 768px) {
        .feedback-content {
            width: 100%;
            height: auto;
            padding: 20px;
            flex-wrap: wrap;
        }
        .feedback-img-m {
            display: block;
            width: 148px;
            height: 155px;
            margin: 0 auto 40px auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .feedback-form_field {
            width: 100%;
            margin-bottom: 20px;
        }
        .feedback-form_group {
            margin-bottom: 0;
        }
        .r-flex {
            flex-wrap: wrap;
        }
        .feedback-form input {
            height: 46px;
        }
        .feedback-form button {
            margin: 20px auto;
        }
        .custom-select {
            height: 46px;
            line-height: 46px;
        }

    }

</style>
